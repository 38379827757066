<template>
  <b-row>
    <b-col
      xs="12"
      md="4"
    >
      <b-card title="Servis Talep Eden">
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Firma
            </div>
            <div>{{ request.company }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Talep Eden
            </div>
            <div>{{ request.customer_user }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Proje Numarası
            </div>
            <div>{{ machine.project }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Fabrika / Seri No
            </div>
            <div>{{ machine.serial }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Kurulum Yılı
            </div>
            {{ machine.syear }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Tipi
            </div>
            {{ machine.machine_type }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Modeli
            </div>
            {{ machine.machine_model }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Temizleme Teknolojisi
            </div>
            {{ machine.machine_cleaning }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Durumu
            </div>
            {{ machine.machine_status }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
    <b-col
      xs="12"
      md="8"
    >
      <b-card title="Servis Talebi">
        {{ request.notes }}
      </b-card>
      <b-card title="Yanıtlar">
        <div
          v-for="(answer,key) in answers"
          :key="key"
        >
          <answer-card :answer="answer" />
        </div>
        <hr>
        <b-form-group
          label="Yanıt"
          label-for="answer"
        >
          <b-form-textarea
            id="answer"
            v-model="formData.text"
            placeholder="Yanıt"
          />
        </b-form-group>
        <b-button
          variant="primary"
          :disabled="!formData.text"
          @click="sendAnswer"
        >
          GÖNDER
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import AnswerCard from '@/views/Service_requests/View/AnswerCard'

export default {
  name: 'View',
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormTextarea,
    BButton,
    AnswerCard,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        text: null,
        id_service_requests: this.$route.params.id,
      },
    }
  },
  computed: {
    request() {
      return this.$store.getters['serviceRequests/getService_request']
    },
    machine() {
      return this.$store.getters['machines/getMachine']
    },
    answers() {
      return this.$store.getters['serviceRequestAnswers/getService_request_answers']
    },
    answerSaveStatus() {
      return this.$store.getters['serviceRequestAnswers/getService_request_answerSaveStatus']
    },
  },
  watch: {
    request(val) {
      if (val.id_machines) {
        this.getMachine(val.id_machines)
      }
    },
    answerSaveStatus(val) {
      if (val.status === true) {
        this.formData = {
          text: null,
          id_service_requests: this.$route.params.id,
        }
        this.getAnswers()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getRequest()
    this.getAnswers()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getRequest() {
      this.$store.dispatch('serviceRequests/service_requestView', this.$route.params.id)
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
    },
    getAnswers() {
      this.$store.dispatch('serviceRequestAnswers/service_request_answersList', {
        where: {
          'service_request_answers.id_service_requests': this.$route.params.id,
        },
      })
    },
    sendAnswer() {
      this.submitStatus = true
      this.$store.dispatch('serviceRequestAnswers/service_request_answerSave', this.formData)
    },
  },
}
</script>
<style scoped></style>
